const answers = [
  'anton',
  'beach',
  'blood',
  'clash',
  'crazy',
  'cream',
  'death',
  'false',
  'floor',
  'giant',
  'mango',
  'melba',
  'muggy',
  'music',
  'naked',
  'optic',
  'paper',
  'punch',
  'queen',
  'rivet',
  'rocky',
  'short',
  'tower',
  'whale',
  'acorn',
  'arran',
  'henry',
  'knops',
  'marko',
  'maxim',
  'north',
  'siren',
  'tring',
  'tryst',
  'weird',
  'wooha',
  'wylam',
  'acids',
  'alpha',
  'beers',
  'yeast',
  'units',
  'chill',
  'break',
  'color',
  'small',
  'malts',
  'musty',
  'platp',
  'final',
  'fusel',
  'fresh',
  'grist',
  'irish',
  'lager',
  'noble',
  'abbot',
  'after',
  'alice',
  'amber',
  'ankle',
  'ashes',
  'audit',
  'beast',
  'betty',
  'birds',
  'bitch',
  'black',
  'blond',
  'blues',
  'brown',
  'build',
  'burns',
  'bushy',
  'butty',
  'calon',
  'chaos',
  'citra',
  'cliff',
  'crack',
  'cross',
  'curim',
  'devon',
  'dirty',
  'dizzy',
  'dogma',
  'eagle',
  'early',
  'edwin',
  'elbow',
  'elsie',
  'elvis',
  'fever',
  'first',
  'fruit',
  'gadds',
  'ghost',
  'grand',
  'great',
  'green',
  'hairy',
  'harry',
  'hedge',
  'hello',
  'hicks',
  'hobby',
  'honey',
  'hooky',
  'hoppy',
  'india',
  'jimmy',
  'joker',
  'jolly',
  'kings',
  'large',
  'later',
  'leeds',
  'lewes',
  'light',
  'loose',
  'lords',
  'manor',
  'march',
  'marie',
  'monty',
  'nanny',
  'night',
  'organ',
  'otter',
  'panda',
  'peaky',
  'penny',
  'pride',
  'prize',
  'rakau',
  'raven',
  'ripon',
  'rosey',
  'royal',
  'rugby',
  'rumpy',
  'rusty',
  'saint',
  'seven',
  'skull',
  'sneck',
  'snowy',
  'steam',
  'stiff',
  'stout',
  'straw',
  'stuff',
  'sweet',
  'taddy',
  'tawny',
  'three',
  'tiger',
  'tokyo',
  'tolly',
  'trade',
  'twice',
  'umbel',
  'welsh',
  'wheat',
  'white',
  'wight',
  'aroma',
  'camra',
  'draft',
  'ester',
  'pitch',
  'salty',
  'shelf',
  ]
export default answers
